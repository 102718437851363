<template>
    <div class="d-flex">
        <schema-item 
            :entity-key="entityKey" 
            :item-id="parseInt(itemId)" 
            class="schema-item" />

        <schema-tabs
            :key="entityKey+itemId"
            :entity-key="entityKey"
            :item-id="parseInt(itemId)"
            v-bind="$attrs"
            class="flex-grow-1 ml-4" />
    </div>
</template>

<script>
import schemaMixin from '@/features/schemas/mixins/schemaMixin'

export default {
    mixins: [schemaMixin],

    props: {
        itemId: {
            type: [Number, String],
            default: null
        }
    }
}
</script>

<style scoped>
@media screen and (min-width: 600px)
{
    .schema-item {

        width: 300px;
    }
}
</style>